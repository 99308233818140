import React, { useState, useEffect, useRef } from 'react';
import './BackgroundGallery.scss';

import { useUser } from '../../../UserManagment/UserProvider';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DELETE_FILE, UPDATE_FILE } from '../../graphQL/mutations';
import { useCreateFile } from '../../hooks/useCreateFile';
import { BACKGROUND_FILES_BY_USERS, CLASSROOM_BACKGROUND } from '../../graphQL/queries';

import Spinner from 'react-bootstrap/Spinner';
import { S3Service } from '../../../../services/S3.service';
import { v4 as uuid } from 'uuid';
import { ReactComponent as NextIcon } from 'assets/icons/forward.svg';
import DraggableItem from 'components/AdminDashboard/DraggableItem';
import DropTarget from 'components/AdminDashboard/DropTarget';
import { v4 as uuidv4 } from 'uuid';
import { getNavigationHistory } from 'store/reduxToolkit/whiteboard/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { removeLastHistory } from 'store/reduxToolkit/whiteboard/actions';
import { FOLDER_MODES, FOLDER_NAMES } from './constants';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { GET_USER_VISITING } from 'components/UserManagment/StudentProfile/graphql/queries';
import { useUserRole } from 'services/cognito.service';
import { useUpdateClassRoomBackground } from 'components/Whiteboard/hooks/useUpdateClassRoomBackground';
import url from '../../../../assets/background/desktop.jpeg';

const s3Service = new S3Service('public', 'image/png');

const BackgroundGallery = ({ eventCallback, setOpenBackground, sort, filter }) => {
  const { user } = useUser();
  const { userId } = useParams();
  const role = useUserRole();
  const { setClassRoomBackground } = useUpdateClassRoomBackground();
  const { data: userDataVisiting } = useQuery(GET_USER_VISITING, {
    variables: { id: userId }
  });
  const [fileList, setFileList] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileDeleting, setFileDeleting] = useState(null);
  const [fileConfirming, setFileConfirming] = useState(null);
  const navigationHistory = useSelector(getNavigationHistory);
  const [fileListFiltered, setfileListFiltered] = useState([]);
  const [updateFile] = useMutation(UPDATE_FILE);
  const [ChangingFileLocation, setChangingFileLocation] = useState(false);
  const [DraggingFile, setDraggingFile] = useState(false);
  const [ChangeAddButton, setChangeAddButton] = useState(false);
  const [AnchorPoint, setAnchorPoint] = useState(null);
  const [OsDragging, setOsDragging] = useState(true);
  const [ExamMode, setExamMode] = useState(null);
  const dispatch = useDispatch();
  const [estado, setEstado] = useState(false);
  const inputFile = useRef();
  const [getFilesByUsers, { loading: queryLoading, error, data: filesData }] =
    useLazyQuery(BACKGROUND_FILES_BY_USERS, {
      variables: { userId: userId ?? user.attributes.sub, sort },
      fetchPolicy: 'network-only'
    });

  const { createFile } = useCreateFile();
  const [deleteFile] = useMutation(DELETE_FILE);
  const params = useParams();
  const { data: classRoomData } = useQuery(CLASSROOM_BACKGROUND, {
    variables: { id: params.classRoomId }
  });

  const homeDefaultFile = {
    mode: 'file',
    id: 'home-default-file',
    name: window.sessionStorage.getItem('defaultFileName') ?? 'Default File',
    url: url,
    background: null,
    currentFolder: 'default',
    isNew: false
  };

  const saveFile = async fileBlob => {
    setFileUploading(true);

    const file = {
      name: `gallery/file_branak_${uuid()}.png`,
      blob: fileBlob
    };

    try {
      // Create file in S3
      const { fileUrl } = await s3Service.uploadFile(file);

      const fileInput = {
        userId: userId ?? user.attributes.sub,
        name: file.name,
        url: fileUrl,
        mode: 'backgroundFile',
        currentFolder: navigationHistory[navigationHistory.length - 1].id
          ? navigationHistory[navigationHistory.length - 1].id
          : 'default'
      };

      // Create file in DynamoDB
      const { data: fileData } = await createFile({ variables: fileInput });

      if (fileData?.createFile.id) {
        if (filter) fileList.shift();

        setFileList([...fileList, { ...fileData.createFile }]);
      } else throw new Error('Error saving file in DynamoDB');
    } catch (error) {
      console.error('ERROR-SAVE-FILE ->', error.message);
      alert('Error saving new file');
    }

    setFileUploading(false);
  };

  const addFolderToList = e => {
    e.nativeEvent.preventDefault();
    setChangeAddButton(true);
    setTimeout(() => {
      setAnchorPoint(null);
    }, 10);
    if (navigationHistory.length > 1) {
      setfileListFiltered(
        fileListFiltered.concat([
          {
            mode: 'backgroundFolder',
            id: uuidv4(),
            name: 'New Folder',
            currentFolder: navigationHistory[navigationHistory.length - 1],
            isNew: true
          }
        ])
      );
    } else {
      setFileList(
        fileList.concat([
          {
            mode: 'backgroundFolder',
            id: uuidv4(),
            name: 'New Folder',
            isNew: true
          }
        ])
      );
    }
  };

  const refreshAfterUpdate = (id, updated) => {
    setChangeAddButton(false);
    const filterFileList = fileList.filter(file => file.id !== id);
    if (updated) setFileList(filterFileList.concat([updated]));
    else setFileList(filterFileList);
  };

  const confirmDeleteFile = async ({ id: fileId, name: fileName, url: fileUrl, file }) =>
    new Promise(async (response, reject) => {
      try {
        const deleted = await s3Service.deleteFile(fileName);

        if (deleted) {
          const { data } = await deleteFile({ variables: { id: fileId } });

          if (data.deleteFile.id) {
            refreshAfterUpdate(fileId);
            response(true);
          } else throw new Error('delete failed in S3');
        } else throw new Error('delete failed in DynamoDB');
      } catch (error) {
        reject(error.message);
        console.error('ERROR-DELETE-FILE ->', error.message);
        alert('Error deleting file');
      } finally {
        setFileConfirming(null);
        setFileDeleting(null);
        response(true);
        if (fileUrl === classRoomData.backgroundImageUrl) {
          await resetBackground();
        }
      }
    });

  const inputOpen = () => {
    if (!fileUploading) inputFile.current.click();
  };

  const callback = async file => {
    if (file.background) {
      try {
        await setClassRoomBackground(file.background);
        return;
      } catch (error) {
        console.error('Error updating background:', error);
        return;
      }
    }

    try {
      await setClassRoomBackground(file.url);
    } catch (error) {
      console.error('Error updating background:', error);
    }
  };

  const resetBackground = async () => {
    try {
      await setClassRoomBackground(null);
    } catch (error) {
      console.error(error);
    }
  };

  // MOUNTED
  useEffect(() => {
    getFilesByUsers();
    return () => setOpenBackground && setOpenBackground(false);
  }, [estado]);

  const filteringFiles = files => {
    if (navigationHistory.length > 1) {
      setfileListFiltered(
        files.filter(
          file =>
            file.currentFolder === navigationHistory[navigationHistory.length - 1].id
        )
      );
    } else {
      setfileListFiltered(
        files.filter(
          file =>
            file.currentFolder === 'default' ||
            !file.currentFolder ||
            file.currentFolder === null
        )
      );
    }
  };

  useEffect(() => {
    if (filesData) {
      let files = filesData.filesByUsers.items?.map(el => ({ ...el }));
      if (filter) {
        files = files.filter((_, i) => i < filter);
        files = files.reverse();
      }

      setFileList(files);
      filteringFiles(files);
    }
  }, [filesData]);

  useEffect(() => {
    filteringFiles(fileList);
  }, [navigationHistory, fileList]);

  if (error) {
    console.error('ERROR-GET-FILES ->', error.message);
    alert('Error getting files');
    setOpenBackground(false);
  }

  if (queryLoading) {
    return (
      <div className="bran-loading">
        <Spinner animation="border" />
      </div>
    );
  }

  const removeLastInHistory = () => {
    dispatch(removeLastHistory());
  };

  const handleChangeFileFolder = async (file, id) => {
    setChangingFileLocation(true);
    const fileInput = {
      ...file,
      currentFolder: id
    };
    if (file.id !== id) {
      await updateFile({ variables: fileInput })
        .then(folder => {
          setChangingFileLocation(false);
          refreshAfterUpdate(file.id, fileInput);
        })
        .catch(error => {
          console.log('ERROR-UPDATING-FILE ->', error);
          setChangingFileLocation(false);
          alert('Error saving new file');
        });
    } else {
      setChangingFileLocation(false);
      alert("Can't perform this change, it should not be the same folder");
    }
  };

  const handleContextMenu = (e, mode) => {
    e.preventDefault();
    setAnchorPoint(null);
    if (window.innerWidth > 750) setAnchorPoint(e.target);
  };

  const handleSOfiles = async e => {
    e.preventDefault();
    if (OsDragging) {
      const { files } = e.dataTransfer;
      const success = [];
      await Array.from(files).forEach(async file => {
        await saveFile(file);
        success.push(true);
      });
      if (success) {
        console.log('success');
      }
      setDraggingFile(false);
    }
  };

  const fileBackground = mode => {
    if (mode === 'backgroundFolder') {
      return 'transparent';
    }
    if (mode === 'exam-student') {
      return '#42F08C7a';
    }
    if (mode === 'exam-edition') {
      return 'rgba(243, 57, 57, 0.42)';
    }

    return 'inherit';
  };

  return (
    <>
      <div
        className="files-gallery"
        onContextMenu={handleContextMenu}
        onDragOver={() => {
          if (OsDragging) {
            setDraggingFile(true);
          }
        }}
        onDragLeave={() => {
          if (OsDragging) {
            setDraggingFile(false);
          }
        }}
        id="files-gallery"
        onDrop={handleSOfiles}
        onClick={() => {
          setAnchorPoint(null);
          setExamMode(null);
        }}
        style={{ transform: eventCallback ? 'none' : 'scale(0.9)' }}
      >
        <div className="files-head-content">
          <div
            className={
              navigationHistory.length > 1
                ? 'files-header'
                : 'files-header object-opacity'
            }
          >
            <NextIcon
              width="15"
              height="15"
              className="backIcon buttons-animations-3"
              onClick={removeLastInHistory}
            />
            {navigationHistory.map((foldername, index) => {
              return (
                <DropTarget
                  key={foldername.id || `nav-${index}`}
                  types={['file-draggable']}
                  handleDrop={item =>
                    handleChangeFileFolder(
                      item.payload,
                      foldername.id ? foldername.id : FOLDER_NAMES.DEFAULT
                    )
                  }
                  customOverStyle={{
                    background: 'rgba(195, 136, 255, 0.4)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 'fit-content'
                  }}
                >
                  <div className="location-tag">
                    {window.innerWidth > 750 ? (
                      <p>
                        {foldername.name === FOLDER_NAMES.DEFAULT
                          ? 'Home'
                          : foldername.name.length > 20
                          ? `${foldername.name.substring(0, 15)}...`
                          : foldername.name}
                      </p>
                    ) : (
                      <p>
                        {foldername.name === FOLDER_NAMES.DEFAULT
                          ? 'Home'
                          : foldername.name.length > 10
                          ? `${foldername.name.substring(0, 10)}...`
                          : foldername.name}
                      </p>
                    )}
                    {index !== navigationHistory.length - 1 && (
                      <p style={{ margin: '0 5px' }}>{'>'}</p>
                    )}
                  </div>
                </DropTarget>
              );
            })}
          </div>
          {role === 'ADMIN' && (
            <div className="nameGalleryUser-tag">
              <p>{userDataVisiting?.getUser?.name || 'Coordinator'} gallery</p>
            </div>
          )}
        </div>
        <DraggableItem
          key={homeDefaultFile.id}
          payload={homeDefaultFile}
          type={'file-draggable'}
          className="file-container"
          style={{ margin: '0 7px' }}
          ListenNativeEvents
          responsiveDragFeedback
          containerPercentHeight={() => {
            const parentHeight = document.getElementById('files-gallery')?.offsetHeight;
            return parentHeight;
          }}
          enableOSdragging={() => setOsDragging(true)}
          disableOSdragging={() => setOsDragging(false)}
        >
          <div
            className="file-container"
            style={{
              background: fileBackground(homeDefaultFile.mode),
              backgroundSize: 'cover',
              width: '100%',
              maxWidth: '100%',
              boxSizing: 'border-box',
              position: 'relative'
            }}
            onMouseLeave={() => setFileConfirming(null)}
          >
            <React.Fragment>
              <div className="delete-container"></div>
              <img
                loading="lazy"
                className="file"
                src={homeDefaultFile.url}
                alt={`File default`}
                onClick={resetBackground}
                style={{ width: '100%', display: 'block' }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '1%',
                  left: '80%',
                  transform: 'translate(-90%, -10%)',
                  color: '#ae00ffb6',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap'
                }}
              >
                Default background
              </div>
            </React.Fragment>
          </div>
        </DraggableItem>
        {fileListFiltered
          .sort((prev, next) => prev.name - next.name)
          .map((file, i) => (
            <DraggableItem
              key={file.id || `file-${i}`}
              payload={file}
              type={'file-draggable'}
              className="file-container"
              style={{ margin: '0 7px' }}
              ListenNativeEvents
              responsiveDragFeedback
              containerPercentHeight={() => {
                const parentHeight =
                  document.getElementById('files-gallery')?.offsetHeight;
                return parentHeight;
              }}
              enableOSdragging={() => setOsDragging(true)}
              disableOSdragging={() => setOsDragging(false)}
            >
              <div
                key={`file-container-${i}`}
                className="file-container"
                style={{
                  background: fileBackground(file.mode),
                  backgroundSize: 'cover',
                  width: '100%',
                  maxWidth: '100%',
                  boxSizing: 'border-box'
                }}
                onMouseLeave={() => setFileConfirming(null)}
              >
                {file.mode === FOLDER_MODES.BACKGROUND_FILE && (
                  <React.Fragment>
                    <div className="delete-container">
                      {fileConfirming === i ? (
                        <div
                          className="confirm"
                          onClick={() => {
                            setFileDeleting(i);
                            confirmDeleteFile(file);
                          }}
                        >
                          <span>Ready ?</span>
                        </div>
                      ) : (
                        <img
                          className="delete"
                          src="/static/icons/ban.png"
                          alt="Icon delete"
                          onClick={() => setFileConfirming(i)}
                        />
                      )}
                    </div>
                    {fileDeleting === i && (
                      <div className="loading-container" style={{ width: '100%' }}>
                        <Spinner className="bran-spinner" animation="border" />
                      </div>
                    )}
                    <img
                      loading="lazy"
                      className="file"
                      src={file.url}
                      alt={`File ${i + 1}`}
                      onClick={() => callback(file)}
                    />
                  </React.Fragment>
                )}
              </div>
            </DraggableItem>
          ))}
        {role === 'ADMIN' && (
          <div
            className="file-container"
            onClick={inputOpen}
            onContextMenu={addFolderToList}
          >
            <div
              className={window.classnames('add-container', { disabled: fileUploading })}
            >
              {fileUploading ? (
                <Spinner className="bran-spinner" animation="border" />
              ) : (
                <img className="add" src="/static/icons/plus.png" alt="Icono mas" />
              )}
            </div>
          </div>
        )}
        {role === 'ADMIN' && (
          <input
            type="file"
            ref={inputFile}
            value=""
            onChange={e => saveFile(e.target.files[0])}
            style={{ display: 'none' }}
          />
        )}
        {ChangingFileLocation && (
          <div
            className="bran-loading"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              backdropFilter: 'blur(7px)'
            }}
          >
            <Spinner animation="border" />
          </div>
        )}
        {DraggingFile && (
          <div
            className="bran-loading"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: -1
            }}
          >
            <div className="dropTarget">
              <p>Drop files here for save it</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BackgroundGallery;
